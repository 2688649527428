import React from 'react';
import cn from 'classnames';
import useResizeObserver from 'use-resize-observer/polyfilled';

import { useFieldAnimatorConfig, useIsMobile, useTargetName } from 'hooks';
import { AnimationError, ErrorBoundary } from 'ui';
import { Transformer } from 'features/common/animations/Transformer';
import { BET_ANDREAS_MIN_WIDTH_DESKTOP } from 'features/common/constants';
import { StatisticBadge } from 'features/common/features/statisticBadge';
import { getRealTeam } from 'features/common/utils';

export function FieldAnimator({
    TopComponent,
    BottomComponent,
    FieldComponent,
    sport,
    statisticHook,
    animationFn,
    classes,
    fill,
}) {
    const { conditions, targetName } = useTargetName();

    const isMobile = useIsMobile(conditions.isBetandreas ? BET_ANDREAS_MIN_WIDTH_DESKTOP : undefined);

    const { ref, height = 1 } = useResizeObserver();

    const { animationData, store, fieldWidth } = useFieldAnimatorConfig({ isMobile, height });

    const AnimationComponent = animationFn[animationData.currentAnimation];
    const realTeam = getRealTeam(animationData.eventData.team, store);

    return (
        <div className="field-animator" ref={ref}>
            <div className={cn(classes?.fieldWrapper, 'player__frame-wrapper')} style={{ width: fieldWidth() }}>
                <div className={`field field--${sport}`}>
                    {conditions.isMostbet && <TopComponent />}
                    <Transformer>
                        <Transformer>
                            <FieldComponent target={targetName} fill={fill || 'none'} />
                        </Transformer>
                        <Transformer>
                            <ErrorBoundary fallback={AnimationError} key={animationData.currentAnimation}>
                                {animationData.currentAnimation && (
                                    <AnimationComponent {...animationData.eventData} team={realTeam} />
                                )}
                            </ErrorBoundary>
                        </Transformer>
                        {(conditions.isBetandreas || conditions.isVivi) && (
                            <>
                                {BottomComponent && <BottomComponent />}
                                {!BottomComponent && statisticHook && (
                                    <StatisticBadge
                                        useStatisticMap={statisticHook}
                                        animationName={animationData.currentAnimation}
                                    />
                                )}
                            </>
                        )}
                    </Transformer>
                </div>
            </div>
        </div>
    );
}
